export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';

export const PERMISSIONS = {
  REPORTS: 'reports',
  REWARDS: 'rewards',
  CAMPAIGNS: 'campaigns',
  CATALOGS: 'catalogs',
  LOYALTIES: 'loyalties',
  RULES: 'transaction_rules',
  MERCHANTS: 'merchants',
  CUSTOMER_MANAGEMENT: 'customer_management',
  CUSTOMER_MANAGEMENT_REWARD: 'customer_management.rewards',
  CUSTOMER_MANAGEMENT_MERCHANT: 'customer_management.merchant',
  CUSTOMER_MANAGEMENT_LOYALTY: 'customer_management.loyalty',
  CUSTOMER_MANAGEMENT_CAMPAIGN: 'customer_management.campaign',
  CUSTOMER_MANAGEMENT_GAME: 'customer_management.game_tries',
  CUSTOMER_MANAGEMENT_STAMP: 'customer_management.stamps',
  SETTINGS: 'settings',
  SCHEDULE_TASKS: 'scheduled_tasks',
  BULK_ACTIONS: 'bulk_actions',
  AUDIT_LOGS: 'audit_logs',
  BUSINESS_INTELLIGENCE: 'business_intelligence',
  INVENTORY: 'inventories',
  PARTNERS: 'partners',
  WALLET_TRANSACTIONS: 'wallet_transactions',
  USERS: 'users',
  ROLES: 'roles',
  BI_REWARD: 'business_intelligence.reward',
  BI_MERCHANT: 'business_intelligence.merchant',
  BI_LOYALTY: 'business_intelligence.loyalty',
  BI_CAMPAIGN: 'business_intelligence.campaign',
  LEADERBOARD: 'leaderboards',
  BADGES: 'badges',
  INVENTORIES: 'inventories',
  AUDIENCES: 'audiences',
  MICROSITES: 'microsites',
  TRANSFER_REQUESTS: 'transfer_requests'
};
