export enum ViewMode {
  CREATE = 'create',
  READ = 'read',
  EDIT = 'edit'
}

export enum Outcome {
  CAMPAIGN_ENROLLMENT = 'campaign_enrollment',
  GAME_TRY = 'campaign_game_try',
  PUSH_NOTIFICATION = 'campaign_promotion_notification'
}
export enum CampaignType {
  STAMP = 'stamp',
  GAME = 'game',
  QUIZ = 'quiz',
  SURVEY = 'survey',
  INSTANT_REWARD = 'instant_outcome',
  PROGRESS = 'progress',
  QUEST = 'quest'
}

export const outcomeTypeMapping = {
  [Outcome.CAMPAIGN_ENROLLMENT]: 'Campaign Enrollment',
  [Outcome.GAME_TRY]: 'Game Try',
  [Outcome.PUSH_NOTIFICATION]: 'Push Notification'
};

export const campaignTypeMapping = {
  [CampaignType.STAMP]: 'Stamp',
  [CampaignType.GAME]: 'Game',
  [CampaignType.QUIZ]: 'Quiz',
  [CampaignType.SURVEY]: 'Survey'
};

export interface IOutcomeModal {
  // Outcome or task rules
  id: number;
  campaign_type: string;
  outcome_type: string;
  campaign_id: number;
  bonus_tries: number;
  bonus_stamps: number;
  media_tag: string | number[];
  custom_fields: any;
  message_channel: any; // array of strings
  outcome_value?: number | string;
}

export enum SCHEDULEDTASK_STATES {
  ACTIVE = 'active',
  SCHEDULED = 'scheduled',
  PROCESSING = 'processing',
  ENDED = 'ended',
  INACTIVE = 'inactive',
  FAILED = 'failed'
}

export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export const CLEAR_INITIAL_DATA = 'CLEAR_INITIAL_DATA';
