import React, { useMemo } from 'react';
import { TimePickerProps } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { useField, useFormikContext } from 'formik';
import moment from 'moment-timezone';

import { TIME_FORMAT } from 'appConstants';
import { TimePicker } from 'components/Layout/Form';

import FItem from './FItem';

type FItemTimePickerProps = TimePickerProps & {
  name: string;
  label?: string;
  format?: string;
  placeholder?: string;
  formItemProps?: FormItemProps;
  timezone?: string;
  /**
   * Meanwhile the `format` prop used to set format for showing the value in UI,
   * the `storedValueFormat` is supposed to define the format of the stored value
   */
  storedValueFormat?: string;
};

const FItemTimePicker = ({
  name,
  label,
  format = TIME_FORMAT,
  storedValueFormat = 'HH:mm',
  timezone = 'UTC',
  placeholder,
  formItemProps,
  ...props
}: FItemTimePickerProps) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);
  const onTimeChange = (value: moment.Moment, valueString: string) => {
    if (!(value && valueString)) {
      formik.setFieldValue(name, '');
      return;
    }
    const v = moment.tz(value, timezone);
    const time = moment(value);
    v.set({ hour: time.get('hour'), minute: time.get('minute'), second: time.get('second') });
    formik.setFieldValue(name, v.format(storedValueFormat));
  };
  const currentValue = useMemo(() => {
    if (field.value) {
      return moment.tz(`${moment().format('YYYY-MM-DD')} ${field.value}`, timezone);
    }
    return null;
  }, [field.value, timezone]);

  return (
    <FItem meta={meta} label={label} htmlFor={name} {...formItemProps}>
      <TimePicker
        aria-label={label}
        value={currentValue}
        onChange={onTimeChange}
        onBlur={() => formik.setFieldTouched(name)}
        format={format}
        placeholder={placeholder}
        {...props}
      />
    </FItem>
  );
};

export default FItemTimePicker;
