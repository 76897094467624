import { Button } from 'antd';
import styled, { css } from 'styled-components';

type ThemeName =
  | 'activeFilledPreset'
  | 'inactiveFilledPreset'
  | 'activeOutlinePreset'
  | 'inactiveOutlinePreset'
  | 'activeOutLineDangerPreset';
interface CustomOutlineButtonProps {
  margin?: string;
  nomargin?: boolean;
  width?: string;
  height?: string;
  isLowercase?: boolean;
  fullwidth?: boolean;
  disableHover?: boolean;
  hasRightActionIcon?: boolean;
  hasLeftActionIcon?: boolean;
  buttonTheme?: ButtonTheme;
  themeName?: ThemeName;
}
interface ButtonTheme {
  bordercolor?: string;
  backgroundcolor?: string;
  bghovercolor?: string;
  borderhovercolor?: string;
  colorhover?: string;
  color?: string;
}
export const OutlineButtonColors = {
  primary: {
    fc: 'white',
    bg: '#2665ee',
    bc: '#2665ee',
    bcd: '#56607f',
    transparent: 'rgba(255, 255, 255, 0.5);'
  },
  danger: {
    main: '#e7292d'
  },
  active: {
    main: '#7ebe8b'
  },
  deactivate: {
    main: '#56607f'
  }
};

const theme: Record<ThemeName, ButtonTheme> = {
  activeFilledPreset: {
    backgroundcolor: OutlineButtonColors.active.main,
    bghovercolor: OutlineButtonColors.active.main,
    color: OutlineButtonColors.primary.fc,
    colorhover: OutlineButtonColors.primary.fc,
    bordercolor: OutlineButtonColors.active.main,
    borderhovercolor: OutlineButtonColors.active.main
  },
  inactiveFilledPreset: {
    backgroundcolor: OutlineButtonColors.deactivate.main,
    bghovercolor: OutlineButtonColors.deactivate.main,
    color: OutlineButtonColors.primary.fc,
    colorhover: OutlineButtonColors.primary.fc,
    bordercolor: OutlineButtonColors.deactivate.main,
    borderhovercolor: OutlineButtonColors.deactivate.main
  },
  activeOutlinePreset: {
    backgroundcolor: OutlineButtonColors.primary.transparent,
    bghovercolor: OutlineButtonColors.active.main,
    color: OutlineButtonColors.primary.bc,
    colorhover: 'white',
    bordercolor: OutlineButtonColors.primary.bc,
    borderhovercolor: OutlineButtonColors.active.main
  },
  inactiveOutlinePreset: {
    backgroundcolor: OutlineButtonColors.primary.transparent,
    bghovercolor: OutlineButtonColors.deactivate.main,
    color: OutlineButtonColors.primary.bc,
    colorhover: 'white',
    bordercolor: OutlineButtonColors.primary.bc,
    borderhovercolor: OutlineButtonColors.deactivate.main
  },
  activeOutLineDangerPreset: {
    backgroundcolor: OutlineButtonColors.primary.transparent,
    bghovercolor: OutlineButtonColors.danger.main,
    color: OutlineButtonColors.danger.main,
    colorhover: 'white',
    bordercolor: OutlineButtonColors.danger.main,
    borderhovercolor: OutlineButtonColors.danger.main
  }
};
/**
 * StyledButton component
 *
 * This is a styled button component that extends the Ant Design Button component.
 * It supports all props available in the Ant Design Button component plus some custom props.
 *
 * @param {string} [margin] - Custom margin for the button.
 * @param {boolean} [nomargin] - If true, removes the margin.
 * @param {string} [width] - Custom width for the button.
 * @param {string} [height] - Custom height for the button.
 * @param {boolean} [isLowercase] - If true, makes the button text lowercase.
 * @param {boolean} [fullwidth] - If true, makes the button take the full width of its container.
 * @param {boolean} [disableHover] - If true, disables the hover effect.
 * @param {boolean} [hasRightActionIcon] - If true, adds styles for a right action icon.
 * @param {boolean} [hasLeftActionIcon] - If true, adds styles for a left action icon.
 * @param {ButtonTheme} [buttonTheme] - Custom theme for the button.
 * @param {ThemeName} [themeName] - Name of the predefined preset to use.
 *
 * @returns {JSX.Element} - The styled button component.
 */
export const StyledButton = styled(Button)<CustomOutlineButtonProps>`
  &&& {
    margin: ${({ margin, nomargin }) => (nomargin ? '0' : margin ? margin : '0 4px')};
    color: ${({ buttonTheme, themeName }) =>
      buttonTheme?.color || theme[themeName]?.color || 'white'};
    border: ${({ type, buttonTheme, themeName }) =>
      type === 'link'
        ? 'none'
        : `1px solid ${
            buttonTheme?.bordercolor ||
            theme[themeName]?.bordercolor ||
            OutlineButtonColors.primary.bc
          }`};
    background: ${({ buttonTheme, themeName }) =>
      buttonTheme?.backgroundcolor ||
      theme[themeName]?.backgroundcolor ||
      OutlineButtonColors.primary.bg};
    width: ${({ width }) => width || ''};
    font-size: 14px;
    padding: 0em 0.8em;
    min-width: 79px;
    ${({ type }) =>
      type === 'link' &&
      css`
        box-shadow: none;
      `};
    font-weight: normal;
    ${({ height }) =>
      !height
        ? css`
            height: 44px;
          `
        : css`
            height: ${height}px;
          `};
    ${({ fullwidth }) =>
      fullwidth &&
      css`
        width: 100%;
      `}

    &:hover,
    &:active {
      background-color: ${({ type, buttonTheme, themeName }) =>
        buttonTheme?.bghovercolor
          ? buttonTheme?.bghovercolor
          : theme[themeName]?.bghovercolor
          ? theme[themeName]?.bghovercolor
          : type === 'primary'
          ? '#2665ee'
          : 'rgba(251, 121, 1, 0.16)'};
      color: ${({ type, buttonTheme, themeName }) =>
        buttonTheme?.colorhover
          ? buttonTheme?.colorhover
          : theme[themeName]?.colorhover
          ? theme[themeName]?.colorhover
          : type === 'primary'
          ? 'white'
          : '#2665ee'};
      border-color: ${({ type, buttonTheme, themeName }) =>
        buttonTheme?.borderhovercolor
          ? buttonTheme?.borderhovercolor
          : theme[themeName]?.borderhovercolor
          ? theme[themeName]?.borderhovercolor
          : type === 'primary'
          ? '#2665ee'
          : 'rgba(251, 121, 1, 0.16)'};
    }

    &:disabled,
    [disabled] {
      opacity: 0.65;
    }
    /* &:first-child {
      margin-left: 0;
    } */
    &:last-child {
      margin-right: 0;
    }

    ${({ disableHover }) =>
      disableHover &&
      css`
        &:hover {
          cursor: default;
          background-color: ${
            /* @ts-ignore */
            ({ type }) => (type === 'primary' ? '#2665ee' : type === 'default' ? 'white' : '')
          };
          color: ${
            /* @ts-ignore */
            ({ type }) => (type === 'primary' ? 'white' : type === 'default' ? '#FB7901' : '')
          };
        }
      `}

    ${({ hasRightActionIcon }) =>
      hasRightActionIcon &&
      css`
        .anticon {
          cursor: pointer;
          margin-left: 10px;

          &:hover {
            opacity: 0.7;
          }
        }
      `}
    ${({ hasLeftActionIcon }) =>
      hasLeftActionIcon &&
      css`
        .anticon {
          cursor: pointer;
          margin-right: 10px;

          &:hover {
            opacity: 0.7;
          }
        }
      `}
  }
`;

export default StyledButton;
